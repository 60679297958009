import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  useReducer,
} from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../layout";
import { productByCategory } from "../../admin/products/FetchApi";
import { homeReducer, homeState } from "./HomeContext";
import { HomeContext } from ".";
import { Search } from "./Search";
const apiURL = process.env.REACT_APP_API_URL;
const Submenu = ({ category }) => {
  const history = useHistory();
  return (
    <Fragment>
      {/* Submenu Section */}
      <section className="mx-4 mt-24 md:mx-12 md:mt-32 lg:mt-24">
        <div className="flex justify-between items-center">
          <div className="text-sm flex space-x-3">
            <span
              className="hover:text-yellow-700 cursor-pointer"
              onClick={(e) => history.push("/")}
            >
              Shop
            </span>
            <span className="text-yellow-700 cursor-default">{category}</span>
          </div>
          <div>
            <svg
              className="w-3 h-3"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 5l7 7-7 7M5 5l7 7-7 7"
              />
            </svg>
          </div>
        </div>
      </section>
      {/* Submenu Section */}
    </Fragment>
  );
};

const AllProduct = ({ products, categoryInfo }) => {
  const { homeContextData, dispatch } = useContext(HomeContext);

  const history = useHistory();
  const category =
    // products && products.length > 0 ? products[0].pCategory.cName : "";
    categoryInfo ? categoryInfo.scName : "";

  const [displayList, setDisplayList] = useState([]);

  const priceLow2High = (a, b) => +a.pPrice - +b.pPrice;
  const priceHigh2Low = (a, b) => -priceLow2High(a, b);
  const nameA2Z = (a, b) => a.pName.localeCompare(b.pName);
  const nameZ2A = (a, b) => -nameA2Z(a, b);
  const dateNew2Old = (a, b) => -dateOld2New(a, b);
  const dateOld2New = (a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    if (dateA < dateB) {
      return 1;
    } else {
      return -1;
    }
  };
  const bestSeller = (a, b) => +b.pSold - +a.pSold;

  const sortFunctions = [
    nameA2Z,
    nameZ2A,
    priceLow2High,
    priceHigh2Low,
    dateNew2Old,
    dateOld2New,
    bestSeller,
  ];

  const [currentSort, setCurrentSort] = useState(0);
  const [displayLabel, setDisplayLabel] = useState("Filter Products");

  const data = [
    {
      label: "Name A-Z",
    },
    {
      label: "Name Z-A",
    },
    {
      label: "Price Low To High",
    },
    {
      label: "Price High To Low",
    },
    {
      label: "Old To New",
    },
    {
      label: "New To Old",
    },
    {
      label: "BestSelling",
    },
  ];
  useEffect(() => {
    if (products && products.length > 0) {
      let tmpList = [].concat(products).sort(sortFunctions[currentSort]);

      // console.log(products[0]);
      setDisplayList(tmpList);
      setDisplayLabel("" + data[currentSort].label + " ");
    }
  }, [products, currentSort]);

  function SortSelector({ displayLabel }) {
    const [isShown, setIsShown] = useState(false);
    return (
      <>
        {/* <div className="flex items-stretch flex-row m-4 mx-10 py-0 bg-gray-200 h-16 rounded"> */}

        <div
          style={{ width: "350px", marginLeft: "auto", marginRight: "auto" }}
          className="flex items-stretch flex-row m-4 mx-5 py-0 bg-gray-200 h-12 rounded"
        >
          <div
            className="flex px-5 py-0 content-center cursor-pointer"
            onClick={() => setIsShown(true)}
            onMouseEnter={() => setIsShown(true)}
            onMouseLeave={() => setIsShown(false)}
          >
            <button className="font px-2">
              {/* Filter Products > */}
              {displayLabel}
              <i class="fa fa-angle-down"></i>
            </button>

            {isShown && (
              <div className="flex flex-1 items-stretch rounded shadow-2xl">
                {/* <ul className="flex items-stretch"> */}
                <ul className="bg-black-500 border-t-4 border-b-4 border-yellow-500 shadow-2xl">
                  {data.map((item, index) => (
                    <li
                      className="p-0 py-0 hover:bg-blue-800 text-gray-900 items-stretch z-50 relative"
                      onClick={() => {
                        setCurrentSort(index);
                        // setIsShown(false);
                      }}
                    >
                      <div className="bg-gray-100 p-2 px-4 text-sm hover:bg-yellow-500 min-w-full">
                        {item.label}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
  return (
    <Fragment>
      <Submenu category={category} />
      <SortSelector displayLabel={displayLabel} />
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "flex-end",
          padding: "0 1rem 0 0",
          flexDirection: "column",
        }}
      >
        <div className="flex space-x-2">
          <div
            onClick={(e) => {
              console.log("clicked");
              dispatch({
                type: "searchDropdown",
                payload: !homeContextData.searchDropdown,
              });
            }}
            className={`flex items-center space-x-1 cursor-pointer ${
              homeContextData.searchDropdown ? "text-green-700" : ""
            }`}
          >
            <span className="text-md md:text-lg">Search</span>
            <span>
              <svg
                style={{
                  color: "#0f4a21",
                }}
                className="w-4 h-4 text-gray-700 text-yellow-700"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
              </svg>
            </span>
          </div>
        </div>
        <Search />
      </div>
      <section className="m-4 md:mx-8 md:my-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {displayList && displayList.length > 0 ? (
          // products.map((item, index) => {
          displayList.map((item, index) => {
            return (
              // <Fragment key={index}>
              <Fragment key={item._id}>
                <div className="relative col-span-1 m-2">
                  <img
                    onClick={(e) => history.push(`/products/${item._id}`)}
                    className="w-full object-cover object-center cursor-pointer"
                    src={item.pImages[0]}
                    alt=""
                  />
                  <div className="flex items-center justify-between mt-2">
                    <div className="text-gray-600 font-light truncate">
                      {item.pName}
                    </div>
                  </div>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "13.5rem 7rem",
                    }}
                  >
                    {parseInt(item.pOffer) <= 0 ? (
                      <div>Rs. {item.pPrice}.00</div>
                    ) : (
                      <div>
                        <strike> Rs. {item.pPrice}</strike> ₹
                        {item.pPrice -
                          (item.pPrice * parseInt(item.pOffer)) / 100}
                        .00
                      </div>
                    )}
                    <span className="text-yellow-700">
                      {" "}
                      {item?.pColor?.[0]?.color}
                    </span>
                  </div>
                  <div className="absolute top-0 right-0 mx-2 my-2 md:mx-4">
                    <svg
                      className="w-5 h-5 md:w-6 md:h-6 cursor-pointer text-yellow-700"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                      />
                    </svg>
                  </div>
                </div>
              </Fragment>
            );
          })
        ) : (
          <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-2xl">
            {/* No product found */}
          </div>
        )}
      </section>
    </Fragment>
  );
};

const PageComponent = () => {
  const [products, setProducts] = useState(null);
  const [categoryInfo, setCategoryInfo] = useState(null);
  const { catId } = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let responseData = await productByCategory(catId);
      if (responseData && responseData.Products) {
        setProducts(responseData.Products);
        setCategoryInfo(responseData.categoryInfo);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <AllProduct products={products} categoryInfo={categoryInfo} />
    </Fragment>
  );
};

const ProductByCategory = (props) => {
  return (
    <Fragment>
      <Layout children={<PageComponent />} />
    </Fragment>
  );
};

const ProductByCategoryWrapper = (props) => {
  const [data, dispatch] = useReducer(homeReducer, homeState);
  return (
    <Fragment>
      <HomeContext.Provider value={{ homeContextData: data, dispatch }}>
        <Layout children={<ProductByCategory {...props} />} />
      </HomeContext.Provider>
    </Fragment>
  );
};
export default ProductByCategoryWrapper;
