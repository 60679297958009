import React from "react";
import Navber from "../partials/Navber";
import Footer from "../partials/Footer";
import "./terms.scss";

const Shipping = () => {
  return (
    <React.Fragment>
      <Navber />
      <div className="termsContainer">
      <h4 style={{ marginTop: "30px", textAlign: "center" }}>
            Shipping & Payment Policy
          </h4>
          <h4>SHIPPING</h4>

          <p>We aim to dispatch orders within 24 hours of placing the order. We will inform you in case of any delays to this timeline.<br/><br/>

We deliver to Delhi, Noida and Gurugram 7 days a week between 10:00 am to 9:00 pm.<br/><br/>Note: For any other delivery related issues, customers shall reach us at organicals@gmail.com or WhatsApp  along with the full delivery address, order id and the issue faced with delivery.<br/><br/>Payment Method:You can use our website service to place an order and make a payment through Debit Card, Credit Card, Net Banking or UPI payment. If you prefer to make cash on delivery, kindly whatsapp us and we will try to accommodate the request. 
           </p>
        </div>
      <Footer />
    </React.Fragment>
  );
};

export default Shipping;
