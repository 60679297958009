import axios from "axios";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getUserById } from "../dashboardUser/FetchApi";
import { LayoutContext } from "../layout";
import { cartListProduct } from "../partials/FetchApi";
import validCouponCode from "./couponCodes";
import {
  quantity,
  subTotal,
  totalCost,
  shippingPrice,
} from "../partials/Mixins";
import { fetchData, pay } from "./Action";
import { getBrainTreeToken } from "./FetchApi";

const apiURL = process.env.REACT_APP_API_URL;

export const CheckoutComponent = (props) => {
  const history = useHistory();
  const { data, dispatch } = useContext(LayoutContext);
  const [state, setState] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    pincode: "",
    phone: "",
    error: false,
    success: false,
    clientToken: null,
    instance: {},
    couponCode: "",
  });
  const [user, setUser] = useState({});
  console.log(user);
  const [couponCodeError, setCouponCodeError] = useState(null);
  const [isCouponCodeValid, setIsCouponCodeValid] = useState(false);

  useEffect(() => {
    getUserById(JSON.parse(localStorage.getItem("jwt")).user._id).then(
      (responseData) => setUser({ ...(responseData?.User ?? {}) })
    );
    fetchData(cartListProduct, dispatch);
  }, []);

  JSON.parse(localStorage.getItem("cart"));
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    if (!state.addressLine1 || !state.city || !state.pincode) {
      setState({ ...state, error: "Please provide your address" });
      return;
    } else if (!state.phone) {
      setState({ ...state, error: "Please provide your phone number" });
      return;
    }
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    var totalCostPaid =
      totalCost({ couponCode: state.couponCode, isCouponCodeValid }) +
      shippingPrice(state.state);

    const amountData = {
      amount: totalCostPaid,
    };

    const result = await axios.post(`${apiURL}/payment/orders`, amountData);

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    const { amount, id: order_id, currency } = result.data;

    const options = {
      key: process.env.RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: "Organicals",
      description: "Live",
      // image: { logo },
      order_id: order_id,
      handler: async function (response) {
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);

        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          couponCode: isCouponCodeValid ? state.couponCode : null,
          userId: user._id,
        };

        const result = await axios.post(`${apiURL}/payment/success`, data);

        // alert(result.data.msg);
        if (result.data.msg === "success") {
          pay(
            parseInt(amount) / 100,
            result.data.paymentId,
            {
              addressLine1: state.addressLine1,
              addressLine2: state.addressLine2,
              city: state.city,
              state: state.state,
              pincode: state.pincode,
            },
            user,
            state.phone,
            dispatch,
            history
          );
        }
      },
      prefill: {
        name: "",
        email: user.email,
        contact: `+91${state.phone}`,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.on("payment.failed", function (response) {
      alert(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });
    paymentObject.open();
  }

  if (data.loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
        Please wait untill finish
      </div>
    );
  }
  return (
    <Fragment>
      <section className="mx-4 mt-20 md:mx-12 md:mt-32 lg:mt-24">
        <div className="text-2xl mx-2">Order</div>
        {/* Product List */}
        <div className="flex flex-col md:flex md:space-x-2 md:flex-row">
          <div className="md:w-1/2">
            <CheckoutProducts products={data.cartProduct} />
            <div className="flex flex-row py-2">
              <div className="w-1/2">
                <input
                  value={state.couponCode}
                  onChange={(e) =>
                    setState({
                      ...state,
                      couponCode: e.target.value,
                    })
                  }
                  placeholder="Coupon Code"
                  className="border px-4 py-2"
                  style={{ width: "98%" }}
                  type="text"
                />
              </div>
              <div className="w-1/3">
                <div
                  onClick={() => {
                    if (
                      (user.usedCouponCodes || []).includes(state.couponCode)
                    ) {
                      setCouponCodeError("Already used coupon code !!");
                    } else if (
                      !validCouponCode
                        .map((v) => v.code)
                        .includes(state.couponCode)
                    ) {
                      setCouponCodeError("Invalid or exprired coupon code");
                    } else if (
                      validCouponCode
                        .map((v) => v.code)
                        .includes(state.couponCode)
                    ) {
                      setCouponCodeError(null);
                      setIsCouponCodeValid(true);
                    }
                  }}
                  className="w-full px-4 py-2 text-center text-white font-semibold cursor-pointer"
                  style={{
                    background: "#0f4a21",
                    color: "#fff",
                  }}
                >
                  Verify
                </div>
              </div>
            </div>
            {couponCodeError && (
              <div className="flex flex-row py-2">
                <div className="w-full" style={{ color: "red" }}>
                  <div>{couponCodeError}</div>
                </div>
              </div>
            )}
            {isCouponCodeValid && (
              <div className="flex flex-row py-2">
                <div className="w-full" style={{ color: "green" }}>
                  Coupon code applied successfully.
                </div>
              </div>
            )}
            <div className="md:flex md:flex-grow md:space-x-4 border-t-2 md:border-t-0 mx-2 py-4">
              <div>
                <div className="font-semibold text-gray-600 text-sm md:text-left text-right">
                  Subtotal : ₹{totalCost()}.00
                </div>
                <div className="font-semibold text-gray-600 text-sm md:text-left text-right">
                  Shipping Charges : ₹{shippingPrice(state.state)}.00
                </div>
                {isCouponCodeValid && (
                  <div className="font-semibold text-gray-600 text-sm md:text-left text-right">
                    Discount : ₹
                    {totalCost() -
                      totalCost({
                        couponCode: state.couponCode,
                        isCouponCodeValid,
                      })}
                    .00
                  </div>
                )}
              </div>
              <div className="md:flex-grow">
                <div className="font-semibold text-gray-600 text-sm text-right">
                  Total Price : ₹
                  {totalCost({
                    couponCode: state.couponCode,
                    isCouponCodeValid,
                  }) + shippingPrice(state.state)}
                  .00
                </div>
              </div>
              <div
                className="flex space-x-1 py-4"
                style={{ marginTop: "3rem" }}
              ></div>
            </div>
          </div>
          <div className="w-full order-first md:order-last md:w-1/2">
            <Fragment>
              <div
                onBlur={(e) => setState({ ...state, error: false })}
                className="p-4 md:p-8"
              >
                {state.error ? (
                  <div className="bg-red-200 py-2 px-4 rounded">
                    {state.error}
                  </div>
                ) : (
                  ""
                )}
                <div className="flex flex-col py-2">
                  <label htmlFor="address" className="pb-2">
                    Delivery Address
                  </label>
                  <input
                    value={state.addressLine1}
                    onChange={(e) =>
                      setState({
                        ...state,
                        addressLine1: e.target.value,
                        error: false,
                      })
                    }
                    type="text"
                    id="addressLine1"
                    className="border px-4 py-2"
                    placeholder="Receiver's Name *"
                  />
                </div>
                <div className="flex flex-col py-2">
                  <textarea
                    value={state.addressLine2}
                    onChange={(e) =>
                      setState({
                        ...state,
                        addressLine2: e.target.value,
                        error: false,
                      })
                    }
                    type="text"
                    id="addressLine2"
                    className="border px-4 py-2"
                    placeholder="Address"
                  />
                </div>
                <div className="flex flex-row py-2">
                  <div className="w-1/2">
                    <input
                      value={state.city}
                      onChange={(e) =>
                        setState({
                          ...state,
                          city: e.target.value,
                          error: false,
                        })
                      }
                      type="text"
                      id="city"
                      className="border px-4 py-2"
                      style={{ width: "98%" }}
                      placeholder="City *"
                    />
                  </div>
                  <div className="w-1/2" style={{ textAlign: "right" }}>
                    <select
                      value={state.state}
                      onChange={(e) =>
                        setState({
                          ...state,
                          state: e.target.value,
                          error: false,
                        })
                      }
                      type="text"
                      id="state"
                      className="border px-4 py-2"
                      style={{ width: "98%" }}
                      placeholder="State *"
                    >
                      <option value="">Select State *</option>
                      {[
                        "Andhra Pradesh",
                        "Arunachal Pradesh",
                        "Asom (Assam)",
                        "Bihar",
                        "Karnataka",
                        "Kerala",
                        "Chhattisgarh",
                        "Uttar Pradesh",
                        "Goa",
                        "Gujarat",
                        "Haryana",
                        "Himachal Pradesh",
                        "Jammu and Kashmir",
                        "Jharkhand",
                        "West Bengal",
                        "Madhya Pradesh",
                        "Maharashtra",
                        "Manipur",
                        "Meghalaya",
                        "Mizoram",
                        "Nagaland",
                        "Orissa",
                        "Punjab",
                        "Rajasthan",
                        "Sikkim",
                        "Tamil Nadu",
                        "Telangana",
                        "Tripura",
                        "Uttarakhand",
                        "Andaman and Nicobar",
                        "Pondicherry",
                        "Dadra and Nagar Haveli",
                        "Daman and Diu",
                        "Delhi",
                        "Chandigarh",
                        "Lakshadweep",
                      ].map((item, i) => (
                        <option value={item} key={i}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="flex flex-row py-2">
                  <div className="w-1/2">
                    <input
                      value={state.pincode}
                      onChange={(e) =>
                        setState({
                          ...state,
                          pincode: e.target.value,
                          error: false,
                        })
                      }
                      type="number"
                      id="pincode"
                      className="border px-4 py-2"
                      style={{ width: "98%" }}
                      placeholder="Pincode *"
                    />
                  </div>
                  <div className="w-1/2" style={{ textAlign: "right" }}>
                    <input
                      value={"India"}
                      type="text"
                      id="state"
                      className="border px-4 py-2"
                      style={{ width: "98%" }}
                      placeholder="Country *"
                      disabled
                    />
                  </div>
                </div>
                <label htmlFor="phone" className="pb-2">
                  {"Mobile Number"}
                </label>
                <div className="flex flex-row py-2">
                  <div style={{ width: "11%" }}>
                    <input
                      value={"+91"}
                      type="text"
                      id="code"
                      className="border px-4 py-2"
                      style={{ width: "100%" }}
                      placeholder="Country Code *"
                      disabled
                    />
                  </div>
                  <div style={{ width: "89%" }}>
                    <input
                      value={state.phone}
                      onChange={(e) =>
                        setState({
                          ...state,
                          phone: e.target.value,
                          error: false,
                        })
                      }
                      type="number"
                      id="phone"
                      className="border px-4 py-2"
                      style={{ width: "100%" }}
                      placeholder="Your 10 digit mobile number"
                    />
                  </div>
                </div>
                {/* <DropIn
                    options={{
                      authorization: state.clientToken,
                      paypal: {
                        flow: "vault",
                      },
                    }}
                    onInstance={(instance) => (state.instance = instance)}
                  />
                  <div
                    onClick={(e) =>
                      pay(
                        data,
                        dispatch,
                        state,
                        setState,
                        getPaymentProcess,
                        totalCost,
                        history
                      )
                    }
                    className="w-full px-4 py-2 text-center text-white font-semibold cursor-pointer"
                    style={{ background: "#303031" }}
                  >
                    Pay now
                  </div> */}
                {/* <button className="App-link" onClick={displayRazorpay}>
                      Pay ₹500
                  </button> */}
                <div
                  onClick={displayRazorpay}
                  className="w-full px-4 py-2 text-center text-white font-semibold cursor-pointer"
                  style={{
                    background: "#0f4a21",
                    color: "#fff",
                  }}
                >
                  Pay now
                </div>
              </div>
            </Fragment>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

const CheckoutProducts = ({ products }) => {
  const history = useHistory();

  return (
    <Fragment>
      <div className="grid grid-cols-2 md:grid-cols-1">
        {products !== null && products.length > 0 ? (
          products.map((product, index) => {
            return (
              <div
                key={index}
                className="col-span-1 m-2 md:py-6 md:border-t md:border-b md:my-2 md:mx-0 md:flex md:items-center md:justify-between"
              >
                <div className="md:flex md:items-center md:space-x-4">
                  <img
                    onClick={(e) => history.push(`/products/${product._id}`)}
                    className="cursor-pointer md:h-20 md:w-20 object-cover object-center"
                    src={product.pImages[0]}
                    alt="wishListproduct"
                  />
                  <div className="text-lg md:ml-6 truncate">
                    {product.pName}
                  </div>
                  <div className="md:ml-6 font-semibold text-gray-600 text-sm">
                    Price : Rs. 
                    {product.pPrice - (product.pPrice * product.pOffer) / 100}
                    .00{" "}
                  </div>
                  <div className="md:ml-6 font-semibold text-gray-600 text-sm">
                    Quantitiy : {quantity(product._id)}
                  </div>
                  <div className="font-semibold text-gray-600 text-sm">
                    Subtotal : Rs. 
                    {subTotal(
                      product._id,
                      product.pPrice - (product.pPrice * product.pOffer) / 100
                    )}
                    .00
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div>No product found for checkout</div>
        )}
      </div>
    </Fragment>
  );
};

export default CheckoutProducts;
