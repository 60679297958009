import React, { Fragment, useContext, useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../layout";
import { HomeContext } from "./index";
import { subCategoriesByCategory } from "../../admin/subCategories/FetchApi";
import "./style.css";
const apiURL = process.env.REACT_APP_API_URL;

const SubCategoryList = ({ subcategories }) => {
  const history = useHistory();

  return (
    <div className="my-4" style={{ marginTop: "120px" }}>
      <hr />

      <div className="py-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
        {subcategories && subcategories.length > 0 ? (
          subcategories.map((item, index) => {
            return (
              <Fragment key={index}>
                <div
                  onClick={(e) =>
                    history.push(`/products/category/${item._id}`)
                  }
                  className="col-span-1 m-2 flex flex-col items-center justify-center space-y-2 cursor-pointer"
                >
                  <img src={item.scImage} alt="pic" />
                  <div className="font-bold">{item.scName}</div>
                </div>
              </Fragment>
            );
          })
        ) : (
          // <div className="text-xl text-center my-4">No Sub-Category</div>
          <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-2xl">
            <svg
              className="w-12 h-12 animate-spin text-gray-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              ></path>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

const PageComponent = () => {
  const { catId } = useParams();
  const [subcategories, setSubCategories] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let responseData = await subCategoriesByCategory(catId);
      if (responseData && responseData.SubCategories) {
        setSubCategories(responseData.SubCategories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <SubCategoryList subcategories={subcategories} />
    </Fragment>
  );
};

const ProductSubCategory = (props) => {
  return (
    <Fragment>
      <Layout children={<PageComponent />} />
    </Fragment>
  );
};

export default ProductSubCategory;
