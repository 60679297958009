import React from "react";
import Navber from "../partials/Navber";
import Footer from "../partials/Footer";
import "./terms.scss";

const Return = () => {
  return (
    <React.Fragment>
      <Navber />
      <div className="termsContainer">
        <h3 style={{ textAlign: "center" }}>Cancellation Policy </h3>
        <h3  style={{marginTop:"30px"}}> Return Policy: </h3>

<p>
  We,organicals follows a discipline quality check before
  shipping the product. We take return of the product if it is in
  damaged condition.
</p>

<h3>Conditions:</h3>

<li>We request you to take unboxing video to process the return request and clear pictures showing the damaged product(s).<br/><br/>
  We accepts return incase client receives a wrong product or the product is expired or is in damaged condition only.Kindly inform us within 24 hours of receiving the product(s) to process the return request for expired/damaged/ wrong product(s).<br/><br/>
To be eligible for return of an expired item, your item must be unused in the original packaging and in the same condition that you received it. <br/><br/>
Fresh produce, breads and other perishable goods cannot be returned. <br/><br/>
To complete your return, we require a receipt or proof of purchase.<br/><br/>
Products perceived by customers as “near expiry” or “inferior” or the like however well within the consumable period shall not be eligible for refund or return either.Once the return request is accepted by us, we will provide you the details where to ship the product. Once the product received in our side, we will verify and initiate the replacement.The damaged or expired product will be replaced with a new product. 
If any issue or clarification you can reach us to our WhatsApp: +91 9315916485 or E-mail: organicals@gmail.com
     </li>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Return;
