import React, { Fragment, useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { HomeContext } from "./index";
import { getAllCategory } from "../../admin/categories/FetchApi";
//import { subCategoriesByCategory } from "../../admin/subCategories/FetchApi";
import { getAllProduct, productByPrice } from "../../admin/products/FetchApi";
import "./style.css";

import { Link } from "react-router-dom";

const apiURL = process.env.REACT_APP_API_URL;

const CategoryList = () => {
  const history = useHistory();
  const { data } = useContext(HomeContext);
  const [categories, setCategories] = useState(null);

  const [showAll, setShowAll] = useState(false);
  //const [subcategories, setSubCategories] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      let responseData = await getAllCategory();
      if (responseData && responseData.Categories) {
        setCategories(responseData.Categories);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={`${data.categoryListDropdown ? "" : "hidden"} my-4`}>
      <hr />
      <div className="py-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
        {categories && categories.length > 0 ? (
          categories
            .slice(0, showAll ? categories.length : 5)
            .map((item, index) => {
              return (
                <Fragment key={index}>
                  <div
                    onClick={(e) => history.push(`/sub-categories/${item._id}`)}
                    className="col-span-1 m-2 flex flex-col items-center justify-center space-y-2 cursor-pointer"
                  >
                    <img src={item.cImage} alt="pic" />
                    <div className="font-bold">{item.cName}</div>
                  </div>
                </Fragment>
              );
            })
        ) : (
          <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-2xl">
            <svg
              className="w-12 h-12 animate-spin text-gray-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              ></path>
            </svg>
          </div>
        )}
      </div>
      {categories && categories.length > 4 && (
        <div className="col-span-1 m-2 flex flex-col items-center justify-center space-y-2">
          <button
            onClick={() => setShowAll(!showAll)}
            className="font-medium cursor-pointer p-2 border-4 border-gray-200 hover:border-gray-500 rounded-sm"
          >
            Show {showAll ? "Less" : "More"} Categories
          </button>
        </div>
      )}
      {/* <div className="py-1 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {subcategories && subcategories.length > 0 ? (
          subcategories.map((item, index) => {
            return (
              <Fragment key={index}>
                <div
                  onClick={(e) =>
                    history.push(`/products/category/${item._id}`)
                  }
                  className="col-span-1 m-2 flex flex-col items-center justify-center space-y-2 cursor-pointer"
                >
                  <img
                    src={item.scImage}
                    alt="pic" style={{width:'100px'}}
                  />
                  <div className="font-medium">{item.scName}</div>
                </div>
              </Fragment>
            );
          })
        ) : ""}
      </div> */}
    </div>
  );
};

const FilterList = () => {
  const { data, dispatch } = useContext(HomeContext);
  const [range, setRange] = useState(0);

  const rangeHandle = (e) => {
    setRange(e.target.value);
    fetchData(e.target.value);
  };

  const fetchData = async (price) => {
    if (price === "all") {
      try {
        let responseData = await getAllProduct();
        if (responseData && responseData.Products) {
          dispatch({ type: "setProducts", payload: responseData.Products });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      dispatch({ type: "loading", payload: true });
      try {
        setTimeout(async () => {
          let responseData = await productByPrice(price);
          if (responseData && responseData.Products) {
            console.log(responseData.Products);
            dispatch({ type: "setProducts", payload: responseData.Products });
            dispatch({ type: "loading", payload: false });
          }
        }, 700);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const closeFilterBar = () => {
    fetchData("all");
    dispatch({ type: "filterListDropdown", payload: !data.filterListDropdown });
    setRange(0);
  };

  return (
    <div className={`${data.filterListDropdown ? "" : "hidden"} my-4`}>
      <hr />
      <div className="w-full flex flex-col">
        <div className="font-medium py-2"></div>
        <div className="flex justify-between items-center">
          <div onClick={(e) => closeFilterBar()} className="cursor-pointer">
            <svg
              className="w-8 h-8 text-gray-700 hover:bg-gray-200 rounded-full p-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

const Search = () => {
  const { data, dispatch } = useContext(HomeContext);
  const [search, setSearch] = useState("");
  const [productArray, setPa] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const SearchResults = () => {
    if (productArray != null && productArray.length > 0 && search != "") {
      const ListItem = ({ x }) => {
        return (
          <Link to={"/products/" + x._id}>
            <li className="my-1 px-2 border-solid border-2 border-yellow-300 rounded-lg hover:bg-gray-200 hover:border-gray-400">
              <div className="flex flex-row">
                <div className="flex">
                  <div className="px-2 py-2 h-3 justify-items-center">
                    <img
                      src={x.pImages[0]}
                      alt="Product Image"
                      height="70"
                      width="50"
                    />
                  </div>
                </div>
                {/* <div className="flex"> */}
                <div className="flex-col px-10">
                  <div className="text-xsl text-black-1000 font-bold">
                    {x.pName}
                  </div>
                  <div className="text-sm text-gray-600">₹ {x.pPrice}</div>
                </div>
              </div>
            </li>
          </Link>
        );
      };

      const filtered = productArray.filter(
        (product) => product.pName.toLowerCase().includes(search.toLowerCase()) // ||
        // product.pCategory.scName.toLowerCase().includes(search.toLowerCase())
      );

      return (
        <ul>
          {filtered.map((x) => {
            return <ListItem key={x._id} x={x} />;
          })}
        </ul>
      );
    } else {
      return null;
    }
  };

  const searchHandle = (e) => {
    setSearch(e.target.value);
    fetchData(); // can be removed ?
    dispatch({
      type: "searchHandleInReducer",
      payload: e.target.value,
      productArray: productArray,
    });
  };

  const fetchData = async () => {
    dispatch({ type: "loading", payload: true });
    try {
      setTimeout(async () => {
        let responseData = await getAllProduct();
        console.log("res data", responseData);
        if (responseData && responseData.Products) {
          setPa(responseData.Products);
          dispatch({ type: "loading", payload: false });
        }
      }, 700);
    } catch (error) {
      console.log(error);
    }
  };

  const closeSearchBar = () => {
    dispatch({ type: "searchDropdown", payload: !data.searchDropdown });
    fetchData(); // ?? why ?
    dispatch({ type: "setProducts", payload: productArray });
    setSearch("");
    window.location.reload(true);
  };

  return (
    <div className={`${data.searchDropdown ? "" : "hidden"}`}>
      <div
        style={{
          height: "45px",
          borderRadius: "30px",
          border: "1px solid #7bb5cd",
        }}
        className={`${
          data.searchDropdown ? "" : "hidden"
          // } my-4 flex items-center justify-between`}
        } my-4 flex items-center justify-between rounded-lg`}
        // } my-4 mx-10 flex items-center justify-center border-yellow-500 border-solid border-4 rounded-lg w-44`}
      >
        <input
          value={search}
          style={{ marginLeft: "10px", height: "20px" }}
          onChange={(e) => searchHandle(e)}
          // className="px-4 text-xl py-4 focus:outline-none"
          className="px-4 text-xl py-4 focus:outline-none flex-1"
          type="text"
          placeholder="Search products..."
        />
        <div onClick={(e) => closeSearchBar()} className="cursor-pointer">
          <svg
            className="w-8 h-8 text-gray-700 hover:bg-gray-200 rounded-full p-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </div>
      </div>
      <div>
        <SearchResults />
      </div>
    </div>
  );
};

const ProductCategoryDropdown = (props) => {
  return (
    <Fragment>
      <CategoryList />
      <FilterList />
      <Search />
    </Fragment>
  );
};

export default ProductCategoryDropdown;
