import React from "react";
import Navber from "../partials/Navber";
import Footer from "../partials/Footer";
import "./about.scss";
const Contact = () => {
  return (
    <React.Fragment>
      <Navber />
      <div className="aboutContainer">
        <h3 style={{ textAlign: "center" }}>Contact Us</h3>
        <h3>Address:</h3>
<p>24, Greater Kailash-1, M Block, <br/>Block M, Greater Kailash 1,<br/> New Delhi, Delhi 110048, India</p>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Contact;
