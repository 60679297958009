import React from "react";
import { Redirect, useHistory, withRouter } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./BestSeller.scss";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

// function buttonCLicked() {
//   // const history = useHistory();
//   // history.push({
//   //   pathname: "/products/60ed5aabbd5fe23f44a94021",
//   // });
// }

function BestSeller() {
  return (
    <React.Fragment>
      <div className="bestClass">
        <h3>Best Sellers</h3>
        <center>
          {" "}
          <h4></h4>
        </center>
        {/* <Carousel className="imgClass" responsive={responsive}>
          <div>
            <a href="">
              {" "}
              <img src="carcat1.jpg" alt="bestseller" />
            </a>
          </div>
          <div>
            <img src="carcat1.jpg" alt="bestseller" />
          </div>
          <div>
            <img src="carcat1.jpg" alt="bestseller" />
          </div>
          <div>
            <img src="carcat1.jpg" alt="bestseller" />
          </div>
        </Carousel> */}
      </div>
     
    </React.Fragment>
  );
}

export default BestSeller;
