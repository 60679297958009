import React from "react";
import "./HomeDesign.scss";

function HomeDesgin() {
  return (
    <React.Fragment>
      <div className="MainContainer">
        <div className="autoClass">
          <h4  style={{paddingTop:"100px", fontFamily: "Josefin Sans,sans-serif"}}>Organicals</h4>
          <h5 style={{paddingTop:"100px"}}>100'S OF NEW OFFERs</h5>
        </div>
      </div>
    </React.Fragment>
  );
}
export default HomeDesgin;
