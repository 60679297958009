// import React, { useState } from "react";
// import * as FaIcons from "react-icons/fa";
// import * as AiIcons from "react-icons/ai";
// import { Link } from "react-router-dom";
// import { SidebarData } from "./SidebarData";
// import "./Navbar.css";
// import { IconContext } from "react-icons";

// function SideNavbar() {
//   const [sidebar, setSidebar] = useState(false);

//   const showSidebar = () => setSidebar(true);

//   const [isHoveringCatalog, setisHoveringCatalog] = useState(false);
//   const [isHoveringLight, setisHoveringLight] = useState(false);
//   const handleMouseHoverCatalog = (e) => {
//     setisHoveringCatalog(!isHoveringCatalog);
//   };
//   const handleMouseHoverLight=(e)=>{
//     setisHoveringLight(!isHoveringLight);
//   };

//   return (
//     <>
//       <IconContext.Provider value={{ color: "#fff" }}>
//         <div className="navbar">
//           <Link to="#" className="menu-bars">
//             {/* <FaIcons.FaBars style={{color:"#000"}} onClick={showSidebar} /> */}
//             <svg
//               style={{
//                 color: "#000",
//                 width: "27px",
//                 position: " absolute",
//                 left: "18px",
//                 top: "25px",
//               }}
//               onClick={showSidebar}
//               className="col-span-1 lg:hidden w-8 h-8 cursor-pointer text-gray-600"
//               fill="none"
//               stroke="currentColor"
//               viewBox="0 0 24 24"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M4 6h16M4 12h16M4 18h16"
//               />
//             </svg>
//           </Link>
//         </div>

//         <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
//           <ul className="nav-menu-items" onClick={showSidebar}>
//             <li className="navbar-toggle">
//               <Link to="#" className="menu-bars">
//                 <AiIcons.AiOutlineClose />
//               </Link>
//             </li>

//             {/* {SidebarData.map((item, index) => {
//               return (
//                 <li key={index} className={item.cName}>
//                   <Link to={item.path}>
//                     {/* {item.icon} */}
//             {/* <span style={{ marginLeft: "16px" }}>{item.title}</span> */}
//             {/* </Link> */}
//             {/* </li> */}
//             {/* ); */}
//             {/* })} */}
//             <li className="nav-text">
//               <Link to="/">
//                 <AiIcons.AiFillHome />

//                 <span
//                   onClick={() => handleMouseHoverCatalog()}
//                   style={{ marginLeft: "16px" }}
//                 >
//                   Catalog
//               <i style={{paddingLeft:"3px",fontWeight:"bold"}} class="fa fa-angle-down"></i>

//                   {isHoveringCatalog && (
//                     <div
//                       style={{
//                         top: "130px",
//                         position:"absolute",
//                       }}
//                     >
//                       <ul style={{ fontSize: "16px"}}>
//                         <Link to="/">
//                           <li style={{marginBottom:"10px"}}  className="hoveringList">Tyre</li>
//                         </Link>
//                         <Link to="/">
//                           <li style={{marginBottom:"10px"}}  className="hoveringList">Audio </li>
//                         </Link>
//                         <Link to="/">
//                           <li style={{marginBottom:"10px"}}  className="hoveringList">Alloys</li>
//                         </Link>
//                         <Link to="/">
//                           <li style={{marginBottom:"10px"}}  className="hoveringList">Wheel</li>
//                         </Link>
//                       </ul>
//                     </div>
//                   )}
//                 </span>
//               </Link>
//             </li>
//             <li className="nav-text">
//               <Link to="/">
//                 <AiIcons.AiFillHome />

//                 <span
//                   onClick={() => handleMouseHoverLight()}
//                   style={{ marginLeft: "16px" }}
//                 >
//                   Lights
//               <i style={{paddingLeft:"3px",fontWeight:"bold"}} class="fa fa-angle-down"></i>

//                   {isHoveringLight && (
//                     <div
//                       style={{
//                         top: "20px",
//                         position:"absolute",
//                       }}
//                     >
//                       <ul style={{ fontSize: "16px"}}>
//                         <Link to="/">
//                           <li style={{marginBottom:"10px"}}  className="hoveringList">Tyre</li>
//                         </Link>
//                     0    <Link to="/">
//                           <li style={{marginBottom:"10px"}}  className="hoveringList">Audio </li>
//                         </Link>
//                         <Link to="/">
//                           <li style={{marginBottom:"10px"}}  className="hoveringList">Alloys</li>
//                         </Link>
//                         <Link to="/">
//                           <li style={{marginBottom:"10px"}}  className="hoveringList">Wheel</li>
//                         </Link>
//                       </ul>
//                     </div>
//                   )}
//                 </span>
//               </Link>
//             </li>
//           </ul>
//         </nav>
//       </IconContext.Provider>
//     </>
//   );
// }

// export default SideNavbar;

import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";

const Nav = styled.div`
  background: #15171c;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #0f4a21;

  width: 250px;
  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Sidebar = () => {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        {/* <Nav>
          <NavIcon to='#'>
            <FaIcons.FaBars onClick={showSidebar} />
          </NavIcon>
        </Nav> */}
        <svg
          style={{
            color: "#000",
            width: "27px",
            position: " absolute",
            left: "18px",
            top: "25px",
          }}
          onClick={showSidebar}
          className="col-span-1 lg:hidden w-8 h-8 cursor-pointer text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M4 6h16M4 12h16M4 18h16"
          />
        </svg>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon to="#">
              <AiIcons.AiOutlineClose onClick={showSidebar} />
            </NavIcon>
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;
