import React from "react";
import Navber from "../partials/Navber";
import Footer from "../partials/Footer";
import "./about.scss";
const About = () => {
  return (
    <React.Fragment>
      <Navber />
      <div className="aboutContainer">
        <h3>About us</h3>
       <p>
        Welcome to Organicals, where a commitment to health and well-being meets the goodness of nature!<br/><br/>

        At Organicals, we take pride in providing a curated selection of premium organic products that cater to your health-conscious lifestyle. Our passion for organic living is reflected in every item we offer, ensuring that you have access to the finest, purest, and most wholesome products available.</p>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default About;
