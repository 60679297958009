import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import "./footer.scss";
const Footer = (props) => {
  return (
    <Fragment>
      {/* <footer
        style={{ background: "#303031", color: "#87898A" }}
        className="z-10 py-6 px-4 md:px-12 text-center"
      >
        Develop & Design oddu © Copyright {moment().format("YYYY")}
      </footer> */}
      <div className="footerClass">
        {" "}
        <footer className="footerContainer">
          <div>
            <img src={require("../../shop/footer.png")} alt="logo" />
          </div>
          <div className="information">
            <h3>Policies</h3>
            <ul>
              <Link to="/terms-and-condition">
                {" "}
                <li>Terms & Conditions</li>
              </Link>
              <Link to="/privacy-policy">
                {" "}
                <li>Privacy Policy</li>
              </Link>
              <Link to="/return-policy">
                {" "}
                <li>Return Policy</li>
              </Link>
              <Link to="/shipping-payment-policy">
                {" "}
                <li>Shipping & Payment Policy </li>
              </Link>
            </ul>
          </div>
          <div className="information">
            <h3>Quick Links</h3>
            <ul>
              <Link to="/">
                {" "}
                <li>Home</li>
              </Link>
              <Link to="/aboutus">
                {" "}
                <li>About Us</li>
              </Link>
              <Link to="/contact-us">
                {" "}
                <li>Contact Us</li>
              </Link>
            
            </ul>
          </div>
          <div className="information">
            <h3>Contact</h3>
            <ul>
              <li>Email: Organicals@gmail.com</li>
              <li>Contact: +91 93159 16485</li>
           


            </ul>
          </div>
        </footer>
        <p style={{ textAlign: "center", color: "#fff" }}>
          {" "}
          © Copyright {moment().format("YYYY")},Organicals.
        </p>
      </div>
    </Fragment>
  );
};

export default Footer;
